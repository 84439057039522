<template>
    <Form
        v-if="!isLoading"
        class="h-100 column-start"
        :validation-schema="schema"
        :initial-values="formValues"
        @submit="submitForm"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ errors }"
    >
        <div
            class="lg-12 row-baseline pointer mb-8 f-15-grey"
            @click="cancel"
        >
            <arrow-shewron
                :transform="180"
                width="14"
                height="14"
                class="i-green mr-2"
            />
            All QR Users
        </div>
        <div class="column-start">
            <h1 class="f-28-black f-black">Add new QR user</h1>
        </div>
        <div class="row-start pt-40">
            <div class="lg-3 nb-4 md-12 mb-md-2 sm-12 mb-sm-1 column-start">
            </div>
            <div class="lg-6 nb-8 md-12 sm-12 column-start">
                <div class="column-start mb-25 lg-12">
                    <form-input
                        id="company_name"
                        class="lg-12"
                        label="Company Name"
                        name="company_name"
                        placeholder="Company Name"
                        :errors="errors"
                    />
                </div>
                <div class="column-start mb-25 lg-12">
                    <form-input
                        id="first_name"
                        class="lg-12"
                        label="Full Name"
                        name="first_name"
                        placeholder="Full Name"
                        :errors="errors"
                    />
                </div>
                <div class="column-start mb-25 lg-12">
                    <form-input
                        id="email"
                        class="lg-12"
                        label="Email"
                        name="email"
                        placeholder="User email"
                        :errors="errors"
                    />
                </div>
                <div class="column-start mb-25 lg-12">
                    <form-input
                        id="password"
                        class="lg-12"
                        label="Password"
                        name="password"
                        placeholder="User password"
                        :errors="errors"
                    />
                </div>
                <div class="column-start mb-25 lg-12">
                    <form-input
                        id="unique_url"
                        class="lg-12"
                        label="Unique URL"
                        name="code"
                        placeholder="Unique URL"
                        :errors="errors"
                    />
                </div>
            </div>
        </div>
        <div class="page-button-panel w-100">
            <primary-button
                class="mr-5"
                buttonType="submit"
            >
                Save Page
            </primary-button>
            <border-button
                @click="cancel"
            >
                Cancel
            </border-button>
        </div>
    </Form>
    <loader v-else/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ArrowShewron from "../../icons/ArrowShewron";
import * as yup from "yup";
import {Form} from "vee-validate";
import PrimaryButton from "../../ui/base/PrimaryButton";
import BorderButton from "../../ui/base/BorderButton";
import Loader from "../../ui/base/Loader";

export default {
    name: "AddNewQRUser",
    components: {
        ArrowShewron,
        Form,
        PrimaryButton,
        BorderButton,
        Loader
    },
    data() {
        return {
            formValues: {
                first_name: '',
                email: '',
                company_name: '',
                password: '',
                code: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            isLoading: "qrUsers/isLoading"
        }),
        schema() {
            let baseRules = {
                company_name: yup.string().required().label('Company name'),
                first_name: yup.string().required().label('Full name'),
                email: yup.string().email().required().label('Email'),
                password: yup.string().required().min(8).label('Password'),
                code: yup.string().required().label('Unique URL')
            }

            return yup.object(baseRules);
        }
    },
    methods: {
        ...mapActions({
           addQrUser: "qrUsers/addQrUser"
        }),
        submitForm(values) {
            this.addQrUser(values).then(res => {
                if (res) this.cancel();
            })
        },
        onInvalidSubmit({errors}) {
            this.$store.dispatch('notifications/SHOW_VALIDATION_ERROR_NOTIFICATION', errors);
        },
        cancel() {
            this.$router.push({ name: 'QRUsers' })
        }
    }
}
</script>

<style scoped>

</style>
